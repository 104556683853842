import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import './App.css';
import Login from './components/Login'; 
import AboutUs from './components/AboutUs';
import Dashboard from './components/Dashboard';
import Testimonials from './components/Testimonials';
import Content from './components/Content';
import AddUser from './components/AddUser';
import Appointment from './components/Appointment';
import Podcast from './components/Podcast';
import SendEmail from './components/SendEmail';
import MainLayout from './components/MainLayout';
import { Box } from '@mui/material';
import AddTestimonials from './components/AddTestimonials';
import AddAppointment from './components/AddAppointment';
import History from './components/History';
import ProtectedRoute from './ProtectedRoute';
import EditContent from './components/EditContent';
import Video from './components/Video';
import Link from './components/Link';
import EditPodcast from './components/EditPodcast';
import Audio from './components/Audio';
import AddHoliday from './components/AddHoliday';
import Members from './components/Members';
import Subscribed from './components/Subscribed';
import Session from './components/Session';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
          <Route path='/login' element={<Login />}/>
          <Route  element={<MainLayout />} >
            <Route path='dashboard/:params' element={<ProtectedRoute Component={Dashboard}/>} />
            <Route path="aboutus" element={<ProtectedRoute Component={AboutUs}/>} />
            <Route path='testimonials' element={<ProtectedRoute Component={Testimonials}/>} />
            <Route path='content/:paramsone' element={<ProtectedRoute Component={Content}/>} />
            <Route path='adduser' element={<ProtectedRoute Component={AddUser}/>} />
            <Route path='appointment' element={<ProtectedRoute Component={Appointment}/>} />
            <Route path='podcast' element={<ProtectedRoute Component={Podcast}/>} />
            <Route path='members' element={<ProtectedRoute Component={Members}/>} />
            <Route path='subscribed' element={<ProtectedRoute Component={Subscribed}/>} />
            <Route path='session' element={<ProtectedRoute Component={Session}/>} />
            <Route path='sendemail' element={<ProtectedRoute Component={SendEmail}/>} />
            <Route path='addtestimonial' element={<ProtectedRoute Component={AddTestimonials}/>} />
            <Route path='addappointment' element={<ProtectedRoute Component={AddAppointment}/>} />
            <Route path='history' element={<ProtectedRoute Component={History}/>} />
            <Route path='editcontent/:paramsthree' element={<ProtectedRoute Component={EditContent} />} />
            <Route path='video/:paramsfour' element={<ProtectedRoute Component={Video} />} />
            <Route path='link/:paramsfive' element={<ProtectedRoute Component={Link} />} />
            <Route path='podcast/:paramssix' element={<ProtectedRoute Component={EditPodcast} />} />
            <Route path='audio/:paramsseven' element={<ProtectedRoute Component={Audio} />} />
            <Route path='addholiday' element={<ProtectedRoute Component={AddHoliday} />} />
          </Route>
          <Route path='*' element={<Box>Error</Box>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
