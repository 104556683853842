import React from 'react';
import Layout from './Layout';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { fetchAppointments } from '../Service/api';
import { useEffect } from 'react';
import { useState } from 'react';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;

const MobHeading = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
`;

const Content = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;

  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 10px; /* Adjust padding for better mobile layout */
  }
`;


const TestimonialHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const MainHeadingWrapper = styled.div`
  /* Enable horizontal scroll for mobile screens */
  overflow-x: auto;

  /* Hide horizontal scrollbar */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const Item = styled(Box)`
  white-space: nowrap; /* Prevent item text from wrapping */
  overflow: hidden;
  width: 10vw;
`;

const MainHeading = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1%;
  padding-right: 1%;
  min-height: 2vh;
  overflow-x: auto; /* Add horizontal scroll for overflowing content */
  
  /* Media query for mobile devices */
  @media (max-width: 767px) {
    flex-wrap: nowrap; /* Prevent content from wrapping */
  }
`;

const Written = styled(Box)`
  min-height: 1.5vh;
  width: 10vw;
  word-wrap: break-word;
  overflow: hidden;
  display: flex;
  justify-content: start;
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 1%;
`;

const ResponsiveButton = styled(Button)`
  color: white;
  height: 4vh;
  

  @media (max-width: 767px) {
    width: 35vw;
    height: 5vh;
    font-size: 10px;
  }
`;

const ButtonBox = styled(Box)`
  display: flex;
  justify-content: start;
`

const EditContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 1%;
  padding-left: 0;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;

const ScrollableContainer = styled.div`
  /* Enable horizontal scroll for mobile screens */
  overflow-x: auto;

  /* Hide horizontal scrollbar */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const ScrollableItem = styled.span`
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
`;




// Rest of the components and styles remain unchanged...

const Appointment = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 767);
  const [contents, setContents] = useState()

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const random = () => fetchAppointments().then(function(result) {
      setContents(result.data)
      console.log(result);
    })
    random();
  }, [])
  
  

  const navigate = useNavigate();

  return (
    <>
      <Heading>
        <h3>Appointment</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Appointment</span>
        </Typography>
      </Heading>
      <Content>
        <TestimonialHeading>
          <h4 style={{ textAlign: 'start' }}>Appointment</h4>
          <ButtonBox>
            <ResponsiveButton style={{backgroundColor: '#5156be'}} onClick={() => navigate('/history')}>History</ResponsiveButton>
            <ResponsiveButton style={{backgroundColor: '#2ab57d', marginLeft: '1vw'}} onClick={() => navigate('/addappointment')}>Add Appointment</ResponsiveButton>
          </ButtonBox>
        </TestimonialHeading>
        <MainHeadingWrapper>
          <MainHeading>
            {/* Add items that should scroll horizontally here */}
            <Item><h4>Id</h4></Item>
            <Item><h4>Name</h4></Item>
            <Item><h4>Phone</h4></Item>
            <Item><h4>Email</h4></Item>
            <Item><h4>Type</h4></Item>
            <Item><h4>Time</h4></Item>
            <Item><h4>Date</h4></Item>
            <Item><h4></h4></Item>
          </MainHeading>
        </MainHeadingWrapper>
        <UL>
        <ScrollableContainer>
        {contents && contents.map((cont, key) => (
              <EditContent key={key}>
                <Item>{key+1}</Item>
                <Item>{cont.name}</Item>
                <Item>{cont.phone}</Item>
                <Item>{cont.email}</Item>
                <Item>{cont.type}</Item>
                <Item>
                  <ScrollableItem>
                  {cont.appointments.map((appointment, index) => (
                    <span key={index}>
                      {appointment.time}
                      {index < cont.appointments.length - 1 && ', '}
                    </span>
                  ))}
                  </ScrollableItem>
                </Item>
                <Item>
                  <ScrollableItem>
                  {cont.appointments.map((appointment, index) => (
                    <span key={index}>
                      {new Date(appointment.date).toLocaleDateString()}
                      {index < cont.appointments.length - 1 && ', '}
                    </span>
                  ))}
                  </ScrollableItem>
                </Item>
                <Item></Item>
              </EditContent>
            ))}
          </ScrollableContainer>
        </UL>
      </Content>
    </>
  );
};

export default Appointment;
