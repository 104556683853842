import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { getMember, getSubscribed } from '../Service/api';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;

const MobHeading = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  margin-bottom: 6vh;
`;

const Content = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;

  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 10px; /* Adjust padding for better mobile layout */
  }
`;


const TestimonialHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const MainHeadingWrapper = styled.div`
  /* Enable horizontal scroll for mobile screens */
  overflow-x: auto;

  /* Hide horizontal scrollbar */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
`;

const Item = styled(Box)`
  padding: 0 10px;
  white-space: nowrap; /* Prevent item text from wrapping */
`;

const MainHeading = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1%;
  padding-right: 10%;
  min-height: 2vh;
  overflow-x: auto; /* Add horizontal scroll for overflowing content */
  
  /* Media query for mobile devices */
  @media (max-width: 767px) {
    flex-wrap: nowrap; /* Prevent content from wrapping */
  }
`;

const Written = styled(Box)`
  min-height: 1.5vh;
  width: 10vw;
  display: flex;
  word-wrap: break-word;
  overflow: hidden;
  justify-content: start;
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 1%;
`;

const ResponsiveButton = styled(Button)`
  color: white;
  height: 4vh;
  width: 10vw;
  

  @media (max-width: 767px) {
    width: 35vw;
    height: 5vh;
    font-size: 10px;
  }
`;

const ButtonBox = styled(Box)`
  display: flex;
  justify-content: start;
`

const EditContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;


const ScrollableContainer = styled.div`
  /* Enable horizontal scroll for mobile screens */
  overflow-x: auto;

  /* Hide horizontal scrollbar */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;





// Rest of the components and styles remain unchanged...

const Subscribed = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 767);
  const [content, setContent] = useState();

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  
  useEffect(() => {
    const random = () => getSubscribed().then(function(result) {
      setContent(result.data)
      console.log(result.data);
    })
    random();
  }, [])

  const navigate = useNavigate();

  return (
    <>
      {!isMobile && <Heading>
        <h3>Subscribed</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Subscribed</span>
        </Typography>
      </Heading>}
      {isMobile && <MobHeading>
          <h3>Dashboard</h3>
          <Typography>
            <span style={{fontWeight: 600}}>Dashboard</span> &gt; <span style={{fontWeight: 300}}>Dashboard</span>
          </Typography>
        </MobHeading>}
      <Content>
        <TestimonialHeading>
          <h4 style={{ textAlign: 'start' }}>Subscribed</h4>
        </TestimonialHeading>
        <MainHeadingWrapper>
          <MainHeading>
            {/* Add items that should scroll horizontally here */}
            <Item><h4>No.</h4></Item>
            <Item><h4>Name</h4></Item>
            <Item><h4>Contact</h4></Item>
            <Item><h4>Email</h4></Item>
          </MainHeading>
        </MainHeadingWrapper>
        <UL>
        <ScrollableContainer>
            {content && content.map((cont, key) => (
              <EditContent key={key}>
                <Item>{key+1}</Item>
                <Item>{cont.name && cont.name}</Item>
                <Item>{cont.phone}</Item>
                <Item>{cont.email}</Item>
              </EditContent>
            ))}
          </ScrollableContainer>
        </UL>
      </Content>
    </>
  );
};

export default Subscribed;
