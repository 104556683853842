import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import styled from '@emotion/styled';
import { Box, Button, Checkbox, FormControlLabel, Input, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import VideocamIcon from '@mui/icons-material/Videocam';
import CreateIcon from '@mui/icons-material/Create';
import LinkIcon from '@mui/icons-material/Link';
import { createAppointment, fetchAppointments } from '../Service/api';
import axios from 'axios';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;


const MobHeading = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
`;

const MainHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  height: 7vh;
`

const EditHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  height: 7vh;
  @media (max-width: 767px) {
    height: 10vh;
  }
`



const MainContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 100%;
  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 1vh;
    width: 100%;
  }
`;

const ViewContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 40%;

  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 10px; /* Adjust padding for better mobile layout */
    width: 100%;
  }
`;


const MainBox = styled(Box)`
  width: auto;
  display: flex;
  justify-content: space-between;
`

const MobileBox = styled(Box)`
  display: flex;
  flex-direction: column;
`


const ArabicWrapper = styled.div`
  direction: rtl;
`;

const ArabicHeading = styled.h4`
  font-family: 'Arabic Font', Arial, sans-serif;
  text-align: right;
`;

const EnglishHeading = styled.h4`
  display: flex;
  justify-content: start;
`;

const RichTextEditorWrapper = styled(Box)`
  padding: 2%;
  .ql-container {
    border: 1px solid #ccc;
    min-height: 200px;
    text-align: right; /* Align the Arabic text inside the editor to the right */
  }
`;

const Btn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 1vh;
  margin-bottom: 1vh;

  @media (max-width: 767px) {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 1%;
`;

const EditContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 1%;
  padding-left: 0;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;


const EditContentHeading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  min-height: 2vh;
`;

const Written = styled(Box)`
word-wrap: break-word;
  overflow: hidden;
  min-height: 1.5vh;
  width: 10vw;
  display: flex;
  justify-content: start;
`;

const Delete = styled(Box)`
  height: 28px;
  width: 2vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74788D;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }
`;

const ArabicTextField = styled(TextField)`
  direction: rtl;
`;




const AddAppointment = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [type, setType] = useState('')
  const [appointments, setAppointments] = useState([{ time: '', date: '' }]);

  const handleAppointmentChange = (index, field, value) => {
    const updatedAppointments = [...appointments];
    updatedAppointments[index][field] = value;
    setAppointments(updatedAppointments);
  };

  const handleAddAppointment = () => {
    setAppointments([...appointments, { time: '', date: '' }]);
  };

  const handleRemoveAppointment = (index) => {
    const updatedAppointments = [...appointments];
    updatedAppointments.splice(index, 1);
    setAppointments(updatedAppointments);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const handleSubmit = async (e) => {
    const config = {
      headers: {
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
  }
    e.preventDefault();
    // Send the appointment data to the API
    try {
      const response = await axios.post(
        'https://api.resto.oryxintl.co:3000/api/user/create-appointment',
        { name, email, phone, type, appointments },
        config
      );
  
      if (response.status === 201) {
        // Appointment added successfully
        // You can add further handling here, e.g., show a success message
        console.log('Appointment(s) added successfully');
      } else {
        // Appointment failed to add
        // You can add further handling here, e.g., show an error message
        console.error('Failed to add appointment(s)');
      }
    } catch (error) {
      console.error('Failed to add appointment(s):', error);
    }
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  return (
    <>
      {!isMobile && (
        <>
          <Heading>
            <h3>Add Appointment</h3>
            <Typography>
              <span style={{ fontWeight: 600 }}>Forms</span> &gt;{' '}
              <span style={{ fontWeight: 300 }}>Add Appointment</span>
            </Typography>
          </Heading>
          <MainBox>
            <MainContent component="form" onSubmit={handleSubmit}>
              <MainHeading>Add Appointment</MainHeading>
              <RichTextEditorWrapper>
                <EnglishHeading>Name</EnglishHeading>
                <TextField
                  style={{ width: '100%' }}
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <EnglishHeading>Email</EnglishHeading>
                <TextField
                  style={{ width: '100%' }}
                  name="email"
                  value={email} onChange={(e) => setEmail(e.target.value)}
                />

                <EnglishHeading>Phone</EnglishHeading>
                <TextField
                  style={{ width: '100%' }}
                  name="phone"
                  value={phone} onChange={(e) => setPhone(e.target.value)}
                />

                <EnglishHeading>Type</EnglishHeading>
                <Select
                  style={{ width: '100%' }}
                  name="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value="Online">Online</MenuItem>
                  <MenuItem value="Offline">Offline</MenuItem>
                </Select>

                {appointments.map((appointment, index) => (
                  <div key={index}>
                    <label>Time:</label>
                    <input
                      type="time"
                      value={appointment.time}
                      onChange={(e) => handleAppointmentChange(index, 'time', e.target.value)}
                      required
                    />
                    <label>Date:</label>
                    <input
                      type="date"
                      value={appointment.date}
                      onChange={(e) => handleAppointmentChange(index, 'date', e.target.value)}
                      required
                    />
                    {index > 0 && (
                      <button type="button" onClick={() => handleRemoveAppointment(index)}>
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button type="button" onClick={handleAddAppointment}>
                  Add Another Appointment
                </button>


                <Btn>
                  <Button
                    style={{
                      color: 'white',
                      width: '5vw',
                      backgroundColor: '#2ab57d',
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Btn>
              </RichTextEditorWrapper>
            </MainContent>
          </MainBox>
        </>
      )}
      {isMobile && 
      <>
      <Heading>
        <h3>Content</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Content</span>
        </Typography>
      </Heading>
      <MobileBox>
      <MainContent component="form" onSubmit={handleSubmit}>
              <MainHeading>Add Appointment</MainHeading>
              <RichTextEditorWrapper>
                <EnglishHeading>Name</EnglishHeading>
                <TextField
                  style={{ width: '100%' }}
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <EnglishHeading>Email</EnglishHeading>
                <TextField
                  style={{ width: '100%' }}
                  name="email"
                  value={email} onChange={(e) => setEmail(e.target.value)}
                />

                <EnglishHeading>Phone</EnglishHeading>
                <TextField
                  style={{ width: '100%' }}
                  name="phone"
                  value={phone} onChange={(e) => setPhone(e.target.value)}
                />

                <EnglishHeading>Type</EnglishHeading>
                <Select
                  style={{ width: '100%' }}
                  name="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value="Online">Online</MenuItem>
                  <MenuItem value="Offline">Offline</MenuItem>
                </Select>

                {appointments.map((appointment, index) => (
                  <div key={index}>
                    <label>Time:</label>
                    <input
                      type="text"
                      value={appointment.time}
                      onChange={(e) => handleAppointmentChange(index, 'time', e.target.value)}
                      required
                    />
                    <label>Date:</label>
                    <input
                      type="date"
                      value={appointment.date}
                      onChange={(e) => handleAppointmentChange(index, 'date', e.target.value)}
                      required
                    />
                    {index > 0 && (
                      <button type="button" onClick={() => handleRemoveAppointment(index)}>
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button type="button" onClick={handleAddAppointment}>
                  Add Another Appointment
                </button>


                <Btn>
                  <Button
                    style={{
                      color: 'white',
                      width: '5vw',
                      backgroundColor: '#2ab57d',
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Btn>
              </RichTextEditorWrapper>
            </MainContent>
      </MobileBox>
      </>
      }
    </>
  )
}

export default AddAppointment
