import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import styled from '@emotion/styled';
import { Box, Button, Checkbox, FormControlLabel, Input, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import VideocamIcon from '@mui/icons-material/Videocam';
import CreateIcon from '@mui/icons-material/Create';
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate, useParams } from 'react-router-dom';
import { AddVideo, addVideo, deleteV, getMedia, getVideos } from '../Service/api';
import { toast } from 'react-toastify';

const Heading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;


const MobHeading = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
`;

const MainHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  height: 7vh;
`

const EditHeading = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  height: 7vh;
  @media (max-width: 767px) {
    height: 10vh;
  }
`



const MainContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 59%;
  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 1vh;
    width: 100%;
  }
`;

const ViewContent = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 1vh;
  width: 40%;

  @media (max-width: 767px) {
    margin-top: 1vh;
    padding: 10px; /* Adjust padding for better mobile layout */
    width: 100%;
  }
`;


const MainBox = styled(Box)`
  width: auto;
  display: flex;
  justify-content: space-between;
`

const MobileBox = styled(Box)`
  display: flex;
  flex-direction: column;
`


const ArabicWrapper = styled.div`
  direction: rtl;
`;

const ArabicHeading = styled.h4`
  font-family: 'Arabic Font', Arial, sans-serif;
  text-align: right;
`;

const EnglishHeading = styled.h4`
  display: flex;
  justify-content: start;
`;

const RichTextEditorWrapper = styled(Box)`
  padding: 2%;
  .ql-container {
    border: 1px solid #ccc;
    min-height: 200px;
    text-align: right; /* Align the Arabic text inside the editor to the right */
  }
`;

const Btn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 1vh;
  margin-bottom: 1vh;

  @media (max-width: 767px) {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 1%;
`;

const EditContent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1%;
  margin-left: 1%;
  padding-left: 0;
  margin-left: 0;
  height: 5vh;
  background-color: #e9e9ef40;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:nth-of-type(2) {
    background-color: white;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 5px;
    margin-bottom: 5px;
  }
`;


const EditContentHeading = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  min-height: 2vh;
`;

const Written = styled(Box)`
  min-height: 1.5vh;
  width: 10vw;
  display: flex;
  word-wrap: break-word;
  overflow: hidden;
  justify-content: start;
`;

const Delete = styled(Box)`
  height: 28px;
  width: 2vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #74788D;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }
`;

const ArabicTextField = styled(TextField)`
  direction: rtl;
`;




const Video = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [name, setName] = useState('');
  const [arabicName, setArabicName] = useState('');
  const [video, setVideo] = useState('');
  const [contents, setContents] = useState('');

  const handleNameChange = (event) => {
    console.log(event.target.value);
    setName(event.target.value);
  };

  const handleArabicNameChange = (event) => {
    setArabicName(event.target.value);
  };

  const handleVideoFileChange = (event) => {
    setVideo(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  
  const { paramsfour } = useParams();

  const handleSubmit = async (e) => {
    try {
      // Create a FormData object to send the file along with other form data
      e.preventDefault();
      const formData = new FormData();
      formData.append('name', name);
      formData.append('arabicName', arabicName);
      formData.append('path', video);
      const res = await addVideo(formData, paramsfour)
      if (res) {
        toast.success('Posted!') 
      }

    } catch (error) {
      // Handle error cases
      console.error(error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const random = () => getVideos(paramsfour).then(function(result) {
      setContents(result.data)
    })
    random();
  }, [])

  const deleteVi = async(e) => {
    try {
      const result = await deleteV(e, paramsfour);
      if (result) {
        setContents(result.data)
        toast.success('Deleted!')
      }else{
        toast.error('Error in Delete')
      }
    } catch (error) {
      // Handle errors (e.g., show error message)
      console.error(error);
    }
  }

  const navigate = useNavigate()

  const handleClick = async (data) => {
    try {
      const res = await getMedia(data)
      
      console.log(res.data.path);
      if (res && res.data.path) {
        // Use window.open() to open the video link in a new tab
        window.open(`https://api.resto.oryxintl.co:3000/${res.data.path}`, '_blank');
      }
    } catch (error) {
      console.log(error);
    }
  }

  if (typeof contents === 'undefined') {
    return(
      <h1>Loading...</h1>
    )
  }

  

  return (
    <>
      {!isMobile && 
      <>
      <Heading>
        <h3>User</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Forms</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Add User</span>
        </Typography>
      </Heading>
      <MainBox>
      <MainContent component='form' onSubmit={handleSubmit}>
        <MainHeading>
          Add Content
        </MainHeading>
        <RichTextEditorWrapper>
          <EnglishHeading>Name</EnglishHeading>
      <TextField style={{ width: '100%' }} value={name} onChange={handleNameChange} />
      <ArabicWrapper>
        <ArabicHeading>اسم</ArabicHeading>
        <TextField style={{ width: '100%' }} value={arabicName} onChange={handleArabicNameChange} />
      </ArabicWrapper>
      <EnglishHeading>Media</EnglishHeading>
      <Input type="file" name="path" onChange={handleVideoFileChange} />
      <Btn>
        <Button
          style={{ color: 'white', width: '5vw', backgroundColor: '#2ab57d' }}
          type='submit'
        >
          Submit
        </Button>
      </Btn>

        </RichTextEditorWrapper>
        </MainContent>
        <ViewContent>
          <EditHeading>
            Edit Content
          </EditHeading>
          <EditContentHeading>
            <Written><h4>Name</h4></Written>
            <Written><h4>اسم</h4></Written>
            <Written><h4>Media</h4></Written>
            <Written><h4>Action</h4></Written>
        </EditContentHeading>
        <UL>
          {contents && contents.map((cont, key) => {
            return (<EditContent key={key}>
              <Written>{cont.name}</Written>
              <Written>{cont.arname}</Written>
              <Written><Delete onClick={() => handleClick(cont)}><VideocamIcon /></Delete></Written>
              <Written><Delete onClick={() => deleteVi(cont)}><DeleteIcon /></Delete></Written>
            </EditContent>
          )
          }
          )}
          
        </UL>
        </ViewContent>
      </MainBox>
      </>
      }
      {isMobile && 
      <>
      <Heading>
        <h3>Content</h3>
        <Typography>
          <span style={{ fontWeight: 600 }}>Home</span> &gt;{' '}
          <span style={{ fontWeight: 300 }}>Content</span>
        </Typography>
      </Heading>
      <MobileBox>
      <MainContent component='form' onSubmit={handleSubmit}>
        <MainHeading>
          Add Content
        </MainHeading>
        <RichTextEditorWrapper>
          <EnglishHeading>Name</EnglishHeading>
      <TextField style={{ width: '100%' }} value={name} onChange={handleNameChange} />
      <ArabicWrapper>
        <ArabicHeading>اسم</ArabicHeading>
        <TextField style={{ width: '100%' }} value={arabicName} onChange={handleArabicNameChange} />
      </ArabicWrapper>
      <EnglishHeading>Video</EnglishHeading>
      <Input type="file" name="path" onChange={handleVideoFileChange} />
      <Btn>
        <Button
          style={{ color: 'white', width: '5vw', backgroundColor: '#2ab57d' }}
          type='submit'
        >
          Submit
        </Button>
      </Btn>

        </RichTextEditorWrapper>
        </MainContent>
        <ViewContent>
          <EditHeading>
            Edit Content
          </EditHeading>
          <EditContentHeading>
            <Written><h4>Name</h4></Written>
            <Written><h4>اسم</h4></Written>
            <Written><h4>Video</h4></Written>
            <Written><h4>Action</h4></Written>
        </EditContentHeading>
        <UL>
        {contents && contents.map((cont, key) => {
            return (<EditContent key={key}>
              <Written>{cont.name}</Written>
              <Written>{cont.arname}</Written>
              <Written><Delete onClick={() => handleClick(cont)}><VideocamIcon /></Delete></Written>
              <Written><Delete onClick={() => deleteVi(cont)}><DeleteIcon /></Delete></Written>
            </EditContent>
          )
          }
          )}
        </UL>
        </ViewContent>
      </MobileBox>
      </>
      }
    </>
  )
}

export default Video
